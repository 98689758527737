import { PropTypes } from "prop-types"
import { useState, useEffect, Fragment } from "react"
import { useSelector, useDispatch } from "react-redux"
import ReactDragListView from "react-drag-listview/lib/index.js"
import { Card, CardBody, Row, Col, Table } from "reactstrap"
import Countdown from "react-countdown"
import {
  startExam,
  startExamClean,
  finishExam,
  finishExamClean,
  answerQuestionExam,
  answerQuestionExamClean,
  getCoursesByUser,
  generateScore,
} from "store/actions"

import {
  AvField,
  AvForm,
  AvRadioGroup,
  AvRadio,
} from "availity-reactstrap-validation"
import ModalCertificate from "components/Courses/ModalCertificate"
import {
  getExam,
  getExamClean,
  listQuestions,
  listQuestionsClean,
  getQuestion,
  getQuestionClean,
} from "store/actions"
import { Link } from "react-router-dom"
import CourseExamUserIntro from "./CourseExamUserIntro"
import CourseCard from "components/Courses/CourseCard"
import ModalCertificatePdf from "components/Courses/ModalCertificatePdf"
import { LoadingIndicator } from "components/Common/LoadingIndicator"

const canNotApplyExam = () => {
  return (
    <div className="alert alert-warning">
      <h3 className="text-center pt-2">
        <i className="fa fa-exclamation-circle"></i> Es necesario haber
        finalizado las lecciones completas.
      </h3>
    </div>
  )
}

const shuffle = array => {
  if (array) {
    let currentIndex = array.length,
      randomIndex

    // While there remain elements to shuffle...
    while (currentIndex != 0) {
      // Pick a remaining element...
      randomIndex = Math.floor(Math.random() * currentIndex)
      currentIndex--

      // And swap it with the current element.
      ;[array[currentIndex], array[randomIndex]] = [
        array[randomIndex],
        array[currentIndex],
      ]
    }
    return array
  }
  return []
}

const CourseExamUser = props => {
  const dispatch = useDispatch()
  const canAppyExam = props.canApplyExam ?? false
  const idCourse = props.course?.courseId ?? 0
  const [examOnCourse, setExamOnCourse] = useState(false)
  const [answerNotSelected, setAnswerNotSelected] = useState(false)
  const [examUserId, setExamUserId] = useState(0)
  const [startTimer, setStartTimer] = useState(undefined)
  const [examResult, setExamResult] = useState(undefined)
  const [answerValue, setAnswerValue] = useState(0)
  const [questionsToDisplay, setQuestionsToDisplay] = useState([])
  const [answersToDisplay, setAnswersToDisplay] = useState([])
  const [questionIndex, setQuestionIndex] = useState(0)
  const [examRequests, setExamRequests] = useState(0)
  const [showCertificate, setShowCertificate] = useState(false)
  const [answerData, setAnswerData] = useState([])
  const [answerResponse, setAnswerResponse] = useState({})

  const {
    exam,
    loading,
    error,
    done,
    questions,
    loadingQuestions,
    errorQuestions,
    question,
    startExamValue,
    startExamDone,
    startExamLoading,
    finishExamDone,
    finishExamLoading,
    finishExamInfo,
    answerQuestionExamDone,
    answerQuestionExamLoading,
    examError,
    whoIAm,
    courses,
  } = useSelector(store => ({
    whoIAm: store.Profile.user,
    exam: store.ExamGet.exam,
    loading: store.ExamGet.loading,
    error: store.ExamGet.error,
    done: store.ExamGet.done,
    questions: store.QuestionsList.questions,
    loadingQuestions: store.QuestionsList.loading,
    errorQuestions: store.QuestionsList.error,
    question: store.QuestionGet.question,
    startExamValue: store.ExamTry.start,
    startExamDone: store.ExamTry.doneStart,
    startExamLoading: store.ExamTry.loadingStart,
    finishExamDone: store.ExamTry.doneFinish,
    finishExamLoading: store.ExamTry.loadingFinish,
    finishExamInfo: store.ExamTry.finish,
    answerQuestionExamDone: store.ExamTry.doneAnswerQuestion,
    answerQuestionExamLoading: store.ExamTry.loadingAnswerQuestion,
    examError: store.ExamTry.error,
    courses: store.CoursesList.courses,
  }))

  useEffect(() => {
    dispatch(getExam(idCourse))
    return () => {
      dispatch(getExamClean())
      setExamOnCourse(false)
    }
  }, [idCourse])

  const userId = whoIAm?.userId ?? 0
  const examId = exam?.examId ?? 0
  const suggestedCourses = props.course.suggestedCourses

  useEffect(() => {
    dispatch(getCoursesByUser(userId))
  }, [props.course, userId])

  const getCourse = id => {
    return courses.find(course => course.courseId === id)
  }

  useEffect(() => {
    if (examId !== undefined) {
      const questionCount =
        exam?.numQuestions === 0 ? exam?.questions?.length : exam?.numQuestions
      const shuffleQuestions = shuffle(exam?.questions)
      const maxQuestions =
        questionCount < shuffleQuestions.length
          ? exam.numQuestions
          : shuffleQuestions.length
      setQuestionsToDisplay(shuffleQuestions.slice(0, maxQuestions))
    }
  }, [examId])

  useEffect(() => {
    if (question !== undefined) setAnswersToDisplay(shuffle(question.answers))
  }, [question])

  useEffect(() => {
    if (examId !== 0) {
      // GET FROM API
      const examUser = exam?.examUser
      if (examUser) {
        setExamResult(examUser)
      }
    }
    return () => {
      setExamResult(undefined)
    }
  }, [idCourse, examId])

  useEffect(() => {
    if (
      questionsToDisplay[questionIndex] !== undefined &&
      answerResponse[questionsToDisplay[questionIndex].questionId] !== undefined
    ) {
      if (questionsToDisplay[questionIndex].questionType.idQuestionType === 3) {
        const orderedAnswers = []
        answerResponse[questionsToDisplay[questionIndex].questionId].map(
          value => {
            answersToDisplay.map(answer => {
              if (answer.answerId === value) {
                orderedAnswers.push(answer)
              }
            })
          }
        )
        setAnswerData(orderedAnswers)
      } else {
        const response =
          answerResponse[questionsToDisplay[questionIndex].questionId]
        setAnswerValue(response[0])
      }
    } else {
      setAnswerData(answersToDisplay)
      setAnswerValue(0)
    }
    return () => {
      setAnswerData([])
      setAnswerValue(0)
    }
  }, [answersToDisplay])

  const handleStartExam = () => {
    const questions = []
    questionsToDisplay.forEach(question => {
      questions.push(question.questionId)
    })

    const request = {
      examId: examId,
      questions: questions,
    }

    dispatch(startExam(request))
  }

  const handleAnswer = async (event, errors, values) => {
    if (values.answer === 0) {
      setAnswerNotSelected(true)
      return
    }
    if (errors.length > 0) {
      return
    }

    setAnswerNotSelected(false)

    setAnswerResponse({
      ...answerResponse,
      [questionsToDisplay[questionIndex].questionId]: [values.answer],
    })

    const request = {
      examId: examId,
      examUserId: examUserId,
      questionId: questionsToDisplay[questionIndex].questionId,
      idSelectedResponses: [values.answer],
    }

    dispatch(answerQuestionExam(request))
  }

  const handleSubmitOrderedAnswers = () => {
    const selectedResponsens = []
    answerData.map(answer => selectedResponsens.push(answer.answerId))

    setAnswerResponse({
      ...answerResponse,
      [questionsToDisplay[questionIndex].questionId]: selectedResponsens,
    })

    const request = {
      examId: examId,
      examUserId: examUserId,
      questionId: questionsToDisplay[questionIndex].questionId,
      idSelectedResponses: selectedResponsens,
    }

    dispatch(answerQuestionExam(request))
  }

  const Next = () => {
    setQuestionIndex(questionIndex + 1)
    const idQuestion = questionsToDisplay[questionIndex + 1].questionId
    dispatch(getQuestion(idQuestion))
  }

  const Prev = () => {
    setAnswerNotSelected(false)
    setQuestionIndex(questionIndex - 1)
    const idQuestion = questionsToDisplay[questionIndex - 1].questionId
    dispatch(getQuestion(idQuestion))
  }

  const handleFinishExam = () => {
    const request = {
      examId: examId,
      examUserId: examUserId,
    }
    if (examRequests === 0) {
      setExamRequests(1)
      dispatch(finishExam(request))
    }
  }

  const handleShowCertificate = () => {
    setShowCertificate(true)
  }

  const handleRepeatExam = () => {
    setExamOnCourse(false)
    setExamResult(undefined)
    setAnswerResponse({})
    // localStorage.setItem(`myExam-${examId}`, undefined)
    // localStorage.removeItem(`myExam-${examId}`)
  }

  const dragProps = {
    onDragEnd(fromIndex, toIndex) {
      const modifiedData = [...answerData]
      const item = modifiedData.splice(fromIndex, 1)[0]
      modifiedData.splice(toIndex, 0, item)
      setAnswerData(modifiedData)
    },
    nodeSelector: "tr",
    handleSelector: "tr",
  }

  if (startExamDone) {
    const question = questionsToDisplay[questionIndex]
    setExamOnCourse(true)
    setExamUserId(startExamValue.examUserId)
    setStartTimer(Date.now())
    const questionId = questionsToDisplay[questionIndex].questionId
    dispatch(getQuestion(questionId))
    dispatch(startExamClean())
  }

  if (finishExamDone) {
    props.reloadProgress()
    setExamOnCourse(false)
    setExamResult(finishExamInfo)
    setQuestionIndex(0)
    setExamRequests(0)
    //localStorage.setItem(`myExam-${examId}`, JSON.stringify(finishExamInfo))
    dispatch(finishExamClean())
    /*dispatch(
      generateScore({
        examId: examId,
        examUserId: examUserId,
      })
    )*/
  }

  if (answerQuestionExamDone) {
    setAnswersToDisplay([])
    if (questionIndex + 1 < questionsToDisplay.length) Next()
    else handleFinishExam()
    dispatch(answerQuestionExamClean())
  }

  const renderer = ({ hours, minutes, seconds, completed }) => {
    if (completed) {
      // Render a completed state
      if (exam?.examTime) {
        handleFinishExam()
      }
      return (
        <h2 className="alt-celf-color d-flex justify-content-end">
          ¡Se acabó el tiempo!
        </h2>
      )
    } else {
      // Render a countdown
      return (
        <h2 className="alt-celf-color d-flex justify-content-end">
          {`${hours < 10 ? "0" : ""}${hours}`}:
          {`${minutes < 10 ? "0" : ""}${minutes}`}:
          {`${seconds < 10 ? "0" : ""}${seconds}`}
        </h2>
      )
    }
  }

  const sec2time = timeInSeconds => {
    var pad = function (num, size) {
        return ("000" + num).slice(size * -1)
      },
      time = parseFloat(timeInSeconds).toFixed(3),
      minutes = Math.floor(time / 60) % 60,
      seconds = Math.floor(time - minutes * 60)

    return pad(minutes, 2) + ":" + pad(seconds, 2)
  }

  if (loading)
    return (
      <div className="text-center">
        <LoadingIndicator />
      </div>
    )

  if (!canAppyExam) return canNotApplyExam()

  if (props.approved || examResult !== undefined) {
    const pass = examResult?.approved ?? 0
    return (
      <Card>
        <CardBody>
          {props.approved && (
            <div className="d-flex justify-content-center mb-4 btn-big">
              <button
                type="button"
                onClick={props.goToValoration}
                className="btn btn-primary"
              >
                Valorar curso
              </button>
            </div>
          )}
          <h2 className="alt-celf-color">Examen</h2>
          <hr />
          <Row className="mb-4 pb-4">
            <Col md={4}>
              <p>Calificación</p>
              <label
                className={`badge bg-pill pt-2 bg-${
                  props.approved || pass === 1 ? "success" : "danger"
                }`}
              >
                <span style={{ fontSize: "24px" }} className="text-white">
                  {props.approved || pass === 1 ? "Aprobado" : "No aprobado"}
                </span>
              </label>
              {props.approved && (
                <div className="mt-2">
                  <button
                    type="button"
                    className="btn btn-link"
                    onClick={() => handleShowCertificate()}
                    style={{ fontSize: "24px" }}
                  >
                    <i className="fas fa-medal"></i> Ver constancia
                  </button>
                </div>
              )}
            </Col>
            <Col md={4}>
              <p>Tiempo realizado</p>
              <h2>
                {examResult !== undefined
                  ? examResult.seconds === null
                    ? "No finalizó"
                    : `${sec2time(examResult.seconds)} minutos`
                  : "No finalizó"}
              </h2>
            </Col>
            <Col md={4}>
              <p>Preguntas correctas</p>
              {examResult === undefined && <h2>100%</h2>}
              {examResult !== undefined && (
                <h2>
                  {Math.round(
                    (examResult.correctQuestions /
                      examResult.presentedQuestions) *
                      100
                  )}
                  %{" "}
                  <small style={{ fontSize: "14px" }}>
                    {examResult.correctQuestions} de{" "}
                    {examResult.presentedQuestions}
                  </small>
                </h2>
              )}
            </Col>
          </Row>
          {examResult !== undefined && (
            <Row className="mt-4 mb-2">
              <Col>
                <div className="table-responsive">
                  <Table className="table-striped">
                    <thead>
                      <tr>
                        <th>No.</th>
                        <th>Pregunta</th>
                        <th>Tipo respuesta</th>
                        <th>Referencia lección</th>
                        <th>Correcta</th>
                      </tr>
                    </thead>
                    <tbody>
                      {examResult.questions.map((answer, index) => (
                        <tr key={`answer-${index}`}>
                          <td>{index + 1}</td>
                          <td>{answer.question}</td>
                          <td>{answer.questionType}</td>
                          <td>
                            {answer.lesson === "" ? "N/A" : answer.lesson}
                          </td>
                          <td>{answer.correct ? "Sí" : "No"}</td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </div>
              </Col>
            </Row>
          )}
          {examResult !== undefined && (
            <Fragment>
              <Row>
                <Col className="d-flex justify-content-end">
                  {!props.approved && (
                    <button
                      type="button"
                      onClick={handleRepeatExam}
                      className="btn btn-secondary"
                    >
                      Intentar de nuevo
                    </button>
                  )}
                  {props.approved && (
                    <button
                      type="button"
                      onClick={props.goToValoration}
                      className="btn btn-secondary"
                    >
                      Valorar curso
                    </button>
                  )}
                </Col>
              </Row>
              {props.approved &&
                suggestedCourses !== undefined &&
                suggestedCourses.length > 0 && (
                  <Fragment>
                    <br />
                    <div>
                      <h3>Cursos sugeridos</h3>
                      <Row>
                        {suggestedCourses.map((suggested, index) => {
                          const course = getCourse(suggested.courseId)
                          return course !== undefined ? (
                            <div
                              key={`suggested-course-${index}`}
                              className="col-md-4"
                            >
                              <CourseCard course={course} />
                            </div>
                          ) : (
                            <div></div>
                          )
                        })}
                      </Row>
                    </div>
                  </Fragment>
                )}
            </Fragment>
          )}
        </CardBody>
        <ModalCertificatePdf
          open={showCertificate}
          exam={exam}
          user={whoIAm}
          course={props.course}
          onClose={() => setShowCertificate(false)}
        />
      </Card>
    )
  }

  if (examOnCourse) {
    return questionsToDisplay[questionIndex] !== undefined ? (
      answerQuestionExamLoading ? (
        <LoadingIndicator />
      ) : (
        <Fragment>
          {examError !== "" && (
            <div className="alert alert-warning">
              Ocurrió un error al guardar la información. {examError}
            </div>
          )}
          <Row>
            <Col sm={8}>
              <h2 className="alt-celf-color">Examen</h2>
            </Col>
            <Col sm={4}>
              <Countdown
                date={startTimer + exam?.examTime * 60 * 1000}
                renderer={renderer}
                intervalDelay={0}
                precision={6}
              />
            </Col>
          </Row>
          <hr />
          <h4>
            Pregunta {questionIndex + 1} de {questionsToDisplay.length}
          </h4>
          <Row>
            <Col md={6}>
              <p style={{ fontSize: "16px" }}>
                {questionsToDisplay[questionIndex].question}
              </p>
              {questionsToDisplay[questionIndex].questionImage !== "" && (
                <Row>
                  <Col md={6} sm={12}>
                    <img
                      src={questionsToDisplay[questionIndex].questionImage}
                      alt="Image"
                      className="img-fluid"
                    />
                  </Col>
                </Row>
              )}
            </Col>
            <Col md={6}>
              {questionsToDisplay[questionIndex].questionType === 3 && (
                <Fragment>
                  <ReactDragListView {...dragProps}>
                    <Table>
                      <thead>
                        <tr>
                          <th width={"10%"}></th>
                          <th width={"90%"}>
                            Ordene las respuesas de manera correcta. Para
                            ordenar las respuestas, de clic y arrastre la
                            respuesta deseada a la posición correcta.
                            <strong>
                              Si lo realiza desde celular, presione 2 veces la
                              respuesta y después arrástrela.
                            </strong>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {(answerData || []).map((answer, index) => {
                          return answer.answer !== "" ? (
                            <tr key={`th-answer-${answer.answerId}`}>
                              <td>
                                <i className="fa fa-grip-vertical"></i>
                              </td>
                              <td>{answer.answer}</td>
                            </tr>
                          ) : (
                            <div></div>
                          )
                        })}
                      </tbody>
                    </Table>
                  </ReactDragListView>
                  <div className="d-flex justify-content-end mt-4">
                    {questionIndex > 0 && (
                      <button
                        type="button"
                        className="btn btn-light"
                        onClick={() => Prev()}
                      >
                        Anterior
                      </button>
                    )}
                    <span>&nbsp;</span>
                    {questionIndex + 1 < questionsToDisplay.length && (
                      <Fragment>
                        <button
                          type="button"
                          onClick={handleSubmitOrderedAnswers}
                          className={`btn btn-secondary ${
                            answerQuestionExamLoading ? "disabled" : ""
                          }`}
                        >
                          Siguiente
                        </button>
                      </Fragment>
                    )}
                    <span>&nbsp;</span>
                    {questionIndex + 1 >= questionsToDisplay.length && (
                      <Fragment>
                        <button
                          type="button"
                          onClick={handleSubmitOrderedAnswers}
                          className={`btn btn-secondary ${
                            !finishExamLoading && !answerQuestionExamLoading
                              ? ""
                              : "disabled"
                          }`}
                        >
                          {!finishExamLoading && !answerQuestionExamLoading
                            ? "Finalizar curso"
                            : "Finalizando curso..."}
                        </button>
                      </Fragment>
                    )}
                  </div>
                </Fragment>
              )}
              {questionsToDisplay[questionIndex].questionType !== 3 && (
                <AvForm className="form-horizontal" onSubmit={handleAnswer}>
                  {answerNotSelected && (
                    <div className="alert alert-warning">
                      Seleccione la respuesta correcta.
                    </div>
                  )}
                  <AvRadioGroup
                    name="answer"
                    className="form-control noNorder"
                    onChange={e => setAnswerValue(parseInt(e.target.value))}
                    value={answerValue}
                    validate={{
                      required: {
                        value: true,
                        errorMessage: "Seleccione la respuesta correcta",
                      },
                    }}
                  >
                    {answersToDisplay.map((answer, index) => {
                      return answer.answer !== "" ? (
                        <Row className="pb-3" key={`answer-${answer.answerId}`}>
                          <Col>
                            <AvRadio
                              label={answer.answer}
                              value={answer.answerId}
                            />
                          </Col>
                        </Row>
                      ) : (
                        <div></div>
                      )
                    })}
                  </AvRadioGroup>
                  <div className="d-flex justify-content-end mt-4">
                    {questionIndex > 0 && (
                      <button
                        type="button"
                        className="btn btn-light"
                        onClick={() => Prev()}
                      >
                        Anterior
                      </button>
                    )}

                    {questionIndex + 1 < questionsToDisplay.length && (
                      <Fragment>
                        <button
                          type="submit"
                          className={`btn btn-secondary ${
                            answerQuestionExamLoading ? "disabled" : ""
                          }`}
                        >
                          Siguiente
                        </button>
                      </Fragment>
                    )}

                    {questionIndex + 1 >= questionsToDisplay.length && (
                      <Fragment>
                        <button
                          type="submit"
                          className={`btn btn-secondary ${
                            !finishExamLoading && !answerQuestionExamLoading
                              ? ""
                              : "disabled"
                          }`}
                        >
                          {!finishExamLoading && !answerQuestionExamLoading
                            ? "Finalizar curso"
                            : "Finalizando curso..."}
                        </button>
                      </Fragment>
                    )}
                  </div>
                </AvForm>
              )}
            </Col>
          </Row>
        </Fragment>
      )
    ) : (
      <div></div>
    )
  }

  return (
    <CourseExamUserIntro
      exam={exam}
      course={props.course}
      handleStartExam={handleStartExam}
      startExamLoading={startExamLoading}
    />
  )
}

CourseExamUser.propTypes = {
  canApplyExam: PropTypes.bool,
  course: PropTypes.object,
  approved: PropTypes.bool,
  goToValoration: PropTypes.func,
  reloadProgress: PropTypes.func,
}

export default CourseExamUser
