import { useDispatch, useSelector } from "react-redux"
import PropTypes from "prop-types"
import ReactQuill from "react-quill"
import "react-quill/dist/quill.snow.css"
import { Fragment, useEffect, useState } from "react"
import { editBlock, editBlockText } from "store/actions"

const CoursesLesonnsFormWysiwyg2 = props => {
  const dispatch = useDispatch()

  const block = props.block

  const onChange = value => {
    setValue(value)
  }

  const saveBlock = () => {
    const request = {
      ...block,
      value,
    }
    dispatch(editBlock(request))
  }

  const [value, setValue] = useState(block.value)

  return (
    <Fragment>
      <ReactQuill
        modules={{
          toolbar: [
            ["bold", "italic", "underline", "strike"], // toggled buttons
            ["blockquote", "code-block"],

            [{ header: 1 }, { header: 2 }], // custom button values
            [{ list: "ordered" }, { list: "bullet" }],
            [{ script: "sub" }, { script: "super" }], // superscript/subscript
            [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
            [{ direction: "rtl" }], // text direction

            [{ size: ["small", false, "large", "huge"] }], // custom dropdown
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            ["link", "image", "video", "formula"], // add's image support
            [{ color: [] }, { background: [] }], // dropdown with defaults from theme
            [{ font: [] }],
            [{ align: [] }],

            ["clean"], // remove formatting button
          ],
        }}
        theme="snow"
        value={value}
        onChange={onChange}
        onBlur={() => {
          saveBlock()
        }}
      />
      <div className="d-flex justify-content-end mt-1">
        <button type="button" onClick={saveBlock} className="btn btn-primary">
          <i className="fa fa-save"></i> Guardar contenido
        </button>
        <div className="ml-2 pl-2">&nbsp;</div>
        {props.removeBlockButton}
      </div>
    </Fragment>
  )
}

CoursesLesonnsFormWysiwyg2.propTypes = {
  block: PropTypes.object,
  removeBlockButton: PropTypes.element,
}

export default CoursesLesonnsFormWysiwyg2
